<cdk-virtual-scroll-viewport
  itemSize="37"
  minBufferPx="900"
  maxBufferPx="1350"
  [ngStyle]="{ height: options.length * 37 + 8 + 'px', maxHeight: '480px' }"
>
  <ion-list style="padding: 4px 0">
    <ion-item
      *cdkVirtualFor="let opt of options; let i = index"
      button="true"
      (click)="didClick(i, opt.label, opt.id, opt.metadata)"
      id="{{ opt.id }}"
      class="ion-item-rounded {{ itemClass ? itemClass : '' }}"
    >
      <ion-label style="margin-left: 5px">
        <ion-icon *ngIf="opt.icon" name="{{ opt.icon }}" class="action-icon"></ion-icon>
        {{ opt.label }}
      </ion-label>
    </ion-item>
  </ion-list>
</cdk-virtual-scroll-viewport>
